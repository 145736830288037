import React, {useEffect} from 'react';
import SlickSlider from '../../UI/Slick'
import SliderData from '../../../data/Slider/home-1'
import {Link} from "gatsby";
import Img from '../../../assets/img/slider/home1.jpg'


const NextArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-right" style={{color:'rgb(255, 201, 102)'}} /></button>
    )
};

const PrevArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-left" style={{color:'rgb(255, 201, 102)'}} /></button>
    )
};


const Slider = () => {


    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed:5000,
        slidesToShow: 1,
        dots: false,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    };

        return (
            <div className={'slider-area'} style={{height:'50%'}}>
                <SlickSlider settings={settings} >
                

                    <div key={'01'}>
                                <div className="slider-item"
                                     style={{backgroundImage: `url(${require('../../../assets/img/slider/home2.jpg')})`}}>
                                   
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-7">
                                                <div className="slider-content" style={{opacity:'1'}}>
                                                    <h2 style={{color:'white'}}>Brindamos apoyo alimentario a personas con inseguridad alimentaria</h2>
                                                    <Link style={{backgroundColor:'#f5a000', transition:'0.2s',transitionDuration:'0.2s', transitionProperty:'all', transitionTimingFunction:'ease', transitionDelay:'0s'}} to={`/ayudamos`} className="btn btn-brand">Podemos ayudarte</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div key={'02'}>
                                <div className="slider-item"
                                     style={{backgroundImage: `url(${require('../../../assets/img/slider/home22.jpeg')})`}}>
                                   
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-7">
                                                <div className="slider-content" style={{opacity:'1'}}>
                                                    <h2 style={{color:'white'}}>Colaboramos con personas, que comparten nuestra preocupación por ayudar.</h2>
                                                    <Link  style={{backgroundColor:'#f5a000', transition:'0.2s',transitionDuration:'0.2s', transitionProperty:'all', transitionTimingFunction:'ease', transitionDelay:'0s'}} to={`/colabora`} className="btn btn-brand">Colabora con nosotros</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div key={'03'}>
                                <div className="slider-item"
                                     style={{backgroundImage: `url(${require('../../../assets/img/slider/home111.jpg')})`}}>
                                   
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-7">
                                                <div className="slider-content" style={{opacity:'1'}}>
                                                    <h2 style={{color:'white'}}>Evitamos el desperdicio</h2>
                                                    <Link  style={{backgroundColor:'#f5a000', transition:'0.2s',transitionDuration:'0.2s', transitionProperty:'all', transitionTimingFunction:'ease', transitionDelay:'0s', backgroundColor:'#f5a000'}} to={`/hacemos`} className="btn btn-brand">Conoce como lo hacemos</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </SlickSlider>
            </div>
        );
    }
;

export default Slider;